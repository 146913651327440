/* eslint-disable no-undef */
import React from "react";
import "./Welcome.css";
const textArray = ['Data is Code', 'Code is Data'];
class WelcomeApp extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
    this.state = { textIdx: 0 };
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    let textThatChanges = textArray[this.state.textIdx % textArray.length];

    return (
      <div className="container">
        <h1 className="data">BINAR</h1>
        
        <p className="heart">&#10084;</p>
        <p className="quote"><span>{textThatChanges}</span></p>
       
      </div>
    )
  }
}

export default WelcomeApp;
